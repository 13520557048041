import React, { useState, useContext, useRef, useLayoutEffect } from "react";
import { Button } from "antd";
import PremiumLogo from "../../../svg/premium/premiumsaving.svg";
import PrivateLogo from "../../../svg/premium/private.svg";
import ScoreLogo from "../../../svg/premium/score.svg";
import SearchLogo from "../../../svg/premium/search.svg";
import WindowSizeContext from "../../contexts/windowSizeContext";
import Earnest from "../../../svg/supplier-color-logos/Earnest.svg";
import Haven from "../../../svg/supplier-color-logos/havenlife.svg";
import Splash from "../../../svg/supplier-color-logos/SplashFinancial.svg";
import BunAndBrad from "../../../svg/supplier-color-logos/DunAndBradstreet.svg";
import CCU from "../../../svg/supplier-color-logos/ConsumerCreditUnion.svg";
import CK from "../../../svg/supplier-color-logos/CreditKarma.svg";
import { v4 as uuidv4 } from "uuid";
import { isLoggedIn } from "../../utils/auth";

export default () => {
  const standardButtonStyles = "text-gray-500 bg-yellow-500 border-yellow-500";
  const focusedButtonStyles = "focus:bg-yellow-500 focus:text-gray-500 focus:border-yellow-500";
  const hoverButtonStyles = "hover:bg-gray-500 hover:text-yellow-500 hover:border-gray-500";

  const windowSize = useContext(WindowSizeContext);

  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const firstRender = useRef(true);
  useLayoutEffect(() => {
    if (firstRender.current) {
      setUserLoggedIn(isLoggedIn());
      firstRender.current = false;
      return;
    }
  }, [userLoggedIn]);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <section className="text-gray-700 body-font">
      <div className="container px-5 py-10 mx-auto lg:py-24">
        <div className="flex flex-wrap overflow-hidden">
          <div className={`${windowSize.isLargeScreenView ? `hidden` : `block`} w-full overflow-hidden lg:w-5/12 xl:w-4/12`}>
            <div className="mx-auto">
              <PremiumLogo className="w-full h-48" />
            </div>
          </div>

          <div className="w-full overflow-hidden lg:w-7/12 xl:w-8/12">
            <div className="pt-2 lg:ml-8">
              <h1 className="w-full text-center lg:text-left">Welcome to The Comparison Company Premium</h1>
              <h3 className="w-full text-center lg:text-left">ADDITIONAL SAVINGS, RESERVED FOR EXISTING CUSTOMERS</h3>
              <h3 className="text-lg text-center text-blue-500 lg:text-left lg:pr-24">
                You have hit a Premium part of The Comparison Company which is available to Customers who have signed up for at least 1 service or contract
              </h3>
              {!userLoggedIn && (
                <div className="py-4 text-center lg:text-left">
                  <p className="inline-block mr-2">Already Premium?</p>
                  <span className="inline-block">
                    <Button
                      type="link"
                      title="Click to Login to The Comparison Company"
                      href="/app/login"
                      className={` h-10 inline-flex items-center align-middle rounded-sm border text-center ${standardButtonStyles} ${hoverButtonStyles} ${focusedButtonStyles} `}
                    >
                      Login Here
                    </Button>
                  </span>
                </div>
              )}
              <p className="text-center lg:text-left">We offer Premium by using existing information to bundle and create unique prices, so create a profile today and watch the savings add up!</p>
              <p className="mt-4 font-bold text-center lg:text-left">Premium Features:</p>
              <ul className="w-full mx-auto mt-4 list-none lg:mx-0 lg:mt-1 ">
                <li key={uuidv4()} className="my-2">
                  <SearchLogo className="inline-block w-full lg:w-4" />
                  <h3 className="inline-block pl-4 text-sm text-center lg:text-left">Smart Search - the best savings from bundled prices</h3>
                </li>
                <li key={uuidv4()} className="my-2">
                  <PrivateLogo className="inline-block w-full lg:w-4" />
                  <h3 className="inline-block pl-4 text-sm text-center lg:text-left">Stay Private - mask your personal contact information</h3>
                </li>
                <li key={uuidv4()} className="my-2">
                  <ScoreLogo className="inline-block w-full lg:w-4 " />
                  <h3 className="inline-block pl-4 text-sm text-center lg:text-left">See Your Score - compare your bills to your neighborhood</h3>
                </li>
              </ul>
              <div className="flex flex-wrap justify-center w-full mt-4 text-center align-middle">
                <Earnest className="inline-block my-2 mr-4" />
                <Haven className="inline-block my-2 mr-4" />
                <Splash className="inline-block my-2 mr-4" />
                <BunAndBrad className="inline-block my-2 mr-4" />
                <CCU className="inline-block my-2 mr-4" />
                <CK className="inline-block my-2 mr-4" />
              </div>
              {!userLoggedIn && (
                <Button
                  type="link"
                  href="/app/login"
                  title="Click to sign up to The Comparison Company"
                  className={`w-full lg:w-64 mt-12 h-12 inline-flex items-center pt-6 align-middle rounded-sm border text-center ${standardButtonStyles} ${hoverButtonStyles} ${focusedButtonStyles}`}
                >
                  <div className="block w-full">Sign Up</div>
                </Button>
              )}
              {userLoggedIn && (
                <Button
                  type="link"
                  href="/"
                  title="Click to search for deals on The Comparison Company"
                  className={`w-full lg:w-64 mt-12 h-12 inline-flex items-center pt-6 align-middle rounded-sm border text-center ${standardButtonStyles} ${hoverButtonStyles} ${focusedButtonStyles}`}
                >
                  <div className="block w-full">Search For Savings</div>
                </Button>
              )}
            </div>
          </div>

          <div className={`${windowSize.isLargeScreenView ? `block` : `hidden`} w-full overflow-hidden lg:w-5/12 xl:w-4/12`}>
            <div className="mr-8">
              <PremiumLogo className="ml-auto" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
